var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full min-contents" }, [
    _c("div", { staticClass: "container mx-auto my-4 mb-8" }, [
      _c("h3", [_vm._v("SRT翻訳ツール")]),
      _c(
        "div",
        { staticClass: "border border-very-light-grey rounded-lg p-4" },
        [_c("SrtTranslator")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }