var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-12 gap-4" },
    [
      _c(
        "div",
        { staticClass: "col-span-4" },
        [
          _c("FlSelect", {
            staticClass: "mt-3",
            attrs: { sublabel: "翻訳元言語", options: _vm.languages },
            model: {
              value: _vm.fromLanguage,
              callback: function ($$v) {
                _vm.fromLanguage = $$v
              },
              expression: "fromLanguage",
            },
          }),
          _c("FlSelect", {
            staticClass: "mt-6",
            attrs: { sublabel: "翻訳先言語", options: _vm.languages },
            model: {
              value: _vm.toLanguage,
              callback: function ($$v) {
                _vm.toLanguage = $$v
              },
              expression: "toLanguage",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-4" },
        [
          _c("FlDnD", {
            attrs: { extensions: ["srt"], uploadMaxCount: 1 },
            on: { input: _vm.processFile },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-4 p-2 rounded bg-light-grey text-sm" },
        [_c("div", { ref: "log", domProps: { innerHTML: _vm._s(_vm.log) } })]
      ),
      _c("NoticeModal", {
        attrs: {
          modalName: "notice",
          title: _vm.modalTitle,
          contents: _vm.modalMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }